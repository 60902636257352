<template>
<div>
   <b-form class="form-document card" @submit="saving">
     <b-row>
     <b-col md="12">
        <span>Nome</span>
        <b-input-group id="input-group-1" label-for="nomeId" class="inpt-grp">
            <b-form-input id="nomeId" size="lg" v-model="form.name" type="text" :disabled="disableField" required></b-form-input>
        </b-input-group>
     </b-col>
     </b-row>

     <b-row>
     <b-col md="3">
        <span>Tipo</span>
        <b-input-group id="input-group-1" label-for="tipoId" class="inpt-grp">
            <b-form-select id="tipoId" size="lg" v-model="typeSelected" :options="optionsTypes" :disabled="disableField" required></b-form-select>
        </b-input-group>
     </b-col>
     <b-col md="9">
        <span>Documento</span>
        <b-input-group id="input-group-1" label-for="documentId" class="inpt-grp">
            <b-form-input id="documentId" size="lg" v-model="form.document" type="text" :disabled="disableField" required></b-form-input>
        </b-input-group>
     </b-col>
     </b-row>

     <b-row>
     <b-col md="7">
        <span>Fonte</span>
        <b-input-group id="input-group-1" label-for="fonteId" class="inpt-grp">
            <b-form-select id="fonteId" v-model="sourceSelected" size="lg" :options="optionsSource"  :disabled="disableField" required></b-form-select>
        </b-input-group>
     </b-col>
     <b-col md="5" >
         <span>Motivo</span>
        <b-input-group id="input-group-1" label-for="motivoId" class="inpt-grp">
            <b-form-select id="motivoId"  size="lg"  v-model="reasonSelected" :options="optionsReasons" :disabled="disableField" required></b-form-select>
         </b-input-group>
     </b-col>
     </b-row>

     <b-row>
     <b-col md="12">
        <span>Observação:</span>
        <b-input-group id="input-group-1" label-for="observacaoId" class="inpt-grp">
            <b-form-textarea id="observacaoId" size="lg" v-model="form.observation" type="text" :disabled="validateObservation" @change="emitObservation($event)" required></b-form-textarea>
        </b-input-group>
     </b-col>
     </b-row>

     <div>
       <slot name="form-footer-button">
           <div>
            <b-row>
              <b-col></b-col>
              <b-col md="2">
                <b-button variant="outline-primary" @click="$router.push('/list')" class="m-1" block> Cancelar</b-button>
              </b-col>
              <b-col md="2">
                <b-button variant="primary" class="m-1 salvar" block type="submit"> Salvar</b-button>
              </b-col>
            </b-row>
          </div>
       </slot>
     </div>
   </b-form>
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "FormDocument",
  props:[
        'userData',
        'perfil'
      ],
  data() {
    return {
            form: {
                  name:'', 
                  reason:'', 
                  document:'', 
                  observation:'', 
                  source:'', 
                  type:'',
              },

            disableField:false,
            validateObservation: true,
            
            sourceSelected: 'PFLD',
            optionsSource: [
              { value: '', text: '---' },
              { value: 'PFLD', text: 'PFLD'}
            ],

            typeSelected:'',
            optionsTypes: [
                { value: '', text: '---' },
                { value: 'CPF',  text: 'CPF' },
                { value: 'CNPJ',  text: 'CNPJ' }
            ],
            
            reasonSelected: '',
            optionsReasons: [
                { value: '', text: '---' },
            ]
        }
    },
    created(){
      this.getReason();
    },
    mounted () {
      this.$root.pessoaId = sessionStorage.pessoaId;
      if(this.perfil == 'visualization' || this.perfil == 'edition')
      {
        this.form = this.userData   
        this.reasonSelected = this.form.reason
        this.typeSelected = this.form.type
        this.sourceSelected = this.form.source
        this.disableField = true;
      }
      if(this.perfil==='adding' || this.perfil == 'edition')
      {
          this.validateObservation = false;
          if(this.perfil==='adding'){
            this.reasonSelected = '1'
          }
      }
    },
    methods: {
      saving(event){
        event.preventDefault()
        this.showAlert = true;
        let body = this.form;
        body.requestId = uuidv4()
        body.active = true
        body.reason = this.reasonSelected
        body.type = this.typeSelected
        body.source = this.sourceSelected

        this.axios.post("/restrictivelist/save", body)
          .then( resposta => {
            var result = resposta.data;
            if(result.status){
              this.$root.$emit('notify', { label: 'Os dados foram salvos com sucesso', time: 5, type: 'success'});
              this.$router.push("/list");        
            }
            else{
              let bodyErrors = JSON.parse(result.json)
              const message = bodyErrors.errors[0].message;
              this.$root.$emit('notify', { label: 'Erro ao salvar os dados. '+message, time: 10, type: 'danger'});
            }
          }).catch(error => {
            this.$root.$emit('notify', { label: error, time: 10, type: 'danger'});
          })
      },
      emitObservation(event){
        this.$emit("observationEmit", event);
      },
      getReason(){
        if(this.perfil === 'adding'){
          this.optionsReasons.push({
            value:'1', text:'Analise-SF'
          })
        }else{
          this.axios.get("/restrictivelist/reasons").then(response =>{
            let result = response.data.reasons;
            result.forEach(element => {
              this.optionsReasons.push({
                value:element.code, text:element.description
              })
            });
          })
        }
      }
    }
};
</script>

<style scoped>
#login-area {
  height: 100vh;
}
#logo {
  width: 85px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 23px;
}
#submit {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.form-document {
  padding: 20px;
}
.inpt-grp {
  margin-bottom: 15px;
}

span {
  font-weight: bold;
}
</style>