<template>
  <div class="home">
    <Header />
    <b-container>
      <div @click="$router.go(-1)">
        <ButtonReturn
          v-bind:title="'VISUALIZANDO'"
          v-bind:document="document"
        />
      </div>
      <div>
        <FormDocument v-bind:perfil="modeProfile" v-bind:userData="userData">
          <template v-slot:form-footer-button>
            <ButtonsView @excluir="callModal" @editar="editingDatas" />
          </template>
        </FormDocument>
      </div>
    </b-container>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import FormDocument from "@/components/FormDocument.vue";
import ButtonsView from "@/components/ButtonsView.vue";
import ButtonReturn from "@/components/ButtonReturn.vue";

export default {
  name: "ViewDocument",
  components: {
    Header,
    FormDocument,
    ButtonsView,
    ButtonReturn,
  },
  data() {
    return {
      document: this.$route.params.document,
      modeProfile: this.$route.params.modeProfile,
      array: this.$root.rootArray,
      userData: this.$root.rootObject,
    };
  },
  mounted() {
    this.$root.pessoaId = sessionStorage.pessoaId;
  },
  methods: {
    editingDatas() {
      if (this.userData.name.trim().length === 0) {
        this.$root.$emit("notify", {
          label:
            "Não é permitido editar as informações que foram adicionadas em lotes",
          time: 7,
          type: "warning",
        });
        return;
      } else {
        this.modeProfile = "edition";
        this.$router.push({
          name: "EditDocument",
          params: { document: this.document, modeProfile: this.modeProfile },
        });
      }
    },
    callModal() {
      this.$bvModal
        .msgBoxConfirm("Essa ação não poderá ser desfeita", {
          title: "Tem certeza que deseja remover esse usuário?",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "SIM",
          cancelTitle: "NÂO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleting();
          }
        })
        .catch((err) => {
          this.$root.$emit('notify', { label: err, time: 10, type: 'danger'});
        });
    },
  deleting() {

    const body = {
      document: this.userData.document,
      documentType: this.userData.document.length === 14 ? "CNPJ" : "CPF",
      user: this.userData.name,
    };

    return this.axios
      .post("/restrictivelist/remove", body)
      .then((respose) => {
        var result = respose.data;
        if (result.status) {
            this.$root.$emit("notify", {
              label: "Exclusão realizada com sucesso",
              time: 5,
              type: "success",
            });
            this.$router.push("/list");
        } else {
          let bodyErrors = JSON.parse(result.json);
            const message = bodyErrors.errors[0].message;
            this.$root.$emit("notify", {
              label: "Erro durante a exclusão dos dados. " + message,
              time: 10,
              type: "danger",
            });
            this.$router.push("/list");
        }
      })
      .catch((error) => {
          this.$root.$emit("notify", {
              label: error,
              time: 10,
              type: "danger",
            });
      });
  },
}
};
</script>
