<template>
  <div class="home">
    <b-button variant="primary" class="m-1" @click="editarDados">
      EDITAR
    </b-button>
    <b-button variant="danger" class="m-1" @click="excluirDados">
      EXCLUIR
    </b-button>
  </div>
</template>

<script>
export default {
  name: "ButtonsView",
  methods:{
    editarDados() {
      this.$emit("editar");
    },
     excluirDados() {
      this.$emit("excluir");
    }
  }
};
</script>

<style scoped>
.home{
  margin-top:auto;
  margin-bottom:auto;
  text-align:right;
}
</style>