<template>
  <div>
    <b-row>
      <b-col md="0"></b-col>
      <b-col class="return">
        <b-icon icon = "arrow-left"></b-icon> {{title}} {{document}}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ButtonReturn",
  props:[
    'document',
    'title'
  ]
};
</script>
<style scoped>
.return {
  margin-top: 5px;
  margin-bottom: 25px;
  cursor: pointer;
  color: #007bff;
}
</style>